@if (mode === 'dialog') {
  <p-dialog
    [(visible)]="visible"
    appendTo="body"
    styleClass="overflow-hidden"
    [modal]="true"
    [dismissableMask]="false"
    [closeOnEscape]="false"
    [draggable]="false"
    [resizable]="false"
    [focusTrap]="true"
    contentStyleClass="p-0 overflow-hidden"
    [style]="{
      width: '60vw',
      height: '80dvh',
      maxHeight: '90dvh'
    }"
    (onHide)="onDestroy()"
  >
    <ng-template pTemplate="header">
      <div class="flex w-full gap-2 align-items-center">
        <!-- estilo de icono-->
        <div
          class="flex align-items-center justify-content-center surface-100 text-cyan-800 border-circle align-items-center"
          style="width: 2.2rem; height: 2.2rem"
        >
          <i class="far fa-truck-container-empty text-lg"></i>
        </div>
        <!-- estilo encabezado -->
        <div
          class="font-medium text-xl text-900 justify-content-center align-items-center flex gap-2"
        >
          <span class="font-medium">Configuraciones de ejes</span>
        </div>
      </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>

    <ng-template pTemplate="footer">
      <div class="border-top-1 surface-border pt-4">
        <p-button
          pRipple
          icon="pi pi-times"
          label="Cancelar"
          severity="secondary"
          [text]="true"
          (onClick)="visible = false"
          [disabled]="loading || saving"
          class="mr-2"
        />

        <p-button
          [disabled]="
            !selectedConfiguracion ||
            creatingConfiguracion ||
            editedConfiguracion
          "
          pRipple
          icon="pi pi-check"
          label="Seleccionar"
          (onClick)="selectConfiguracion()"
        />
      </div>
    </ng-template>
  </p-dialog>
} @else {
  <div class="header_highlight p-2 w-full mx-2 border-round surface-0 shadow-2">
    <div class="flex p-4 align-items-center justify-content-between row gap-2">
      <div class="flex align-items-center flex-row gap-2">
        <p-button
          class="mt-0"
          routerLink=".."
          pRipple
          severity="secondary"
          [rounded]="true"
          [text]="true"
          styleClass="no-underline"
          icon="pi pi-times"
        />
        <i class="fas fa-truck-container-empty text-2xl text-500"></i>
        <span class="text-3xl font-medium text-900"
          >Configuraciones de Ejes</span
        >
      </div>

      <div class="flex flex-row gap-2"></div>
    </div>
  </div>

  <div
    class="flex flex-column gap-2 p-4 w-full mx-2 mt-4 border-round surface-0 shadow-2"
    style="height: calc(100dvh / var(--zoom) - 20rem)"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
}

<ng-template #contentTemplate>
  <div
    class="surface-100 col h-full flex flex-column col overflow-x-hidden gap-2"
  >
    @if (loading) {
      <div class="h-full w-full flex align-items-center justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    } @else {
      <div class="p-2 flex flex-column h-full">
        <div class="flex-1 overflow-hidden">
          <p-splitter
            [style]="{ height: '100%', border: 'none' }"
            [panelSizes]="[62, 38]"
            class="splitter-border-0"
          >
            <!-- primera parte -->
            <ng-template pTemplate>
              <div
                class="surface-100 border-round-lg col h-full flex flex-column overflow-x-hidden gap-2 bg-white"
              >
                @if (editedConfiguracion || creatingConfiguracion) {
                  <div class="flex flex-column gap-1 w-full">
                    <div
                      class="flex gap-2 justify-content-end align-items-center m-2"
                    >
                      <p-button
                        pRipple
                        label="Cancelar"
                        [disabled]="saving"
                        [outlined]="true"
                        severity="secondary"
                        icon="pi pi-times"
                        (onClick)="cancelConfig()"
                      />
                      <p-button
                        pRipple
                        label="Guardar configuración"
                        [loading]="saving"
                        icon="pi pi-save"
                        (onClick)="saveConfig()"
                      />
                    </div>
                    <div class="p-3 flex flex-row h-full w-full">
                      <div class="flex h-full w-full flex-nowrap">
                        <!-- añade vehiculo -->
                        <div class="flex flex-column gap-2 w-full">
                          <div class="flex flex-column gap-2">
                            <label for="configName"
                              >Nombre de la configuración *</label
                            >
                            <input
                              pInputText
                              type="text"
                              class="w-full"
                              id="configName"
                              [(ngModel)]="configName"
                            />
                          </div>
                          <div
                            class="flex flex-column border-1 border-200 border-round p-2 gap-2"
                          >
                            @if (!filteredOption) {
                              <label for="selectButton"
                                >Selecciona una clase de vehículo</label
                              >
                              <div class="flex justify-content-center">
                                <p-selectButton
                                  id="selectButton"
                                  [options]="opcionesClaseVehiculo"
                                  [(ngModel)]="opcionClaseVehiculo"
                                  (ngModelChange)="configurarEjes()"
                                  optionLabel="label"
                                  styleClass="m-2"
                                  optionValue="value"
                                  [allowEmpty]="false"
                                >
                                  <ng-template pTemplate="item" let-item>
                                    <div
                                      class="flex flex-row gap-2 align-items-center"
                                    >
                                      <i [class]="item.icon"></i>
                                      <span class="z-1">{{ item.label }}</span>
                                    </div>
                                  </ng-template>
                                </p-selectButton>
                              </div>
                            }
                            <label for="dropdown">Configuración de ejes</label>
                            @for (eje of ejes; track $index) {
                              <div
                                class="flex flex-row gap-2 align-items-center w-full justify-content-between"
                              >
                                <p-dropdown
                                  id="dropdown"
                                  [disabled]="
                                    opcionClaseVehiculo === 'isTractora' &&
                                    ($first || $index > 1)
                                  "
                                  class="w-full"
                                  styleClass="w-full"
                                  [options]="ejesOptions"
                                  [(ngModel)]="ejes[$index].tipo"
                                />
                                @if (ejes[$index].tipo === 'm') {
                                  <p-dropdown
                                    class="w-full"
                                    styleClass="w-full"
                                    [options]="ruedasOptions"
                                    [(ngModel)]="ejes[$index].n_ruedas"
                                    (onChange)="ejeChange($index)"
                                  />
                                }
                                @if ($last && ejes.length < 6) {
                                  <ng-container
                                    *ngTemplateOutlet="addAxisButton"
                                  ></ng-container>
                                }
                                @if (
                                  (opcionClaseVehiculo === 'isTractora' &&
                                    $index > 1) ||
                                  (opcionClaseVehiculo === 'isRemolque' &&
                                    $index > 0)
                                ) {
                                  <p-button
                                    severity="danger"
                                    [rounded]="true"
                                    [outlined]="true"
                                    icon="pi pi-trash"
                                    (onClick)="removeAxis($index)"
                                  />
                                }
                              </div>
                            } @empty {
                              <div
                                class="flex flex-row gap-2 align-items-center"
                              >
                                <span class="text-600"
                                  >No hay ejes definidos</span
                                >
                                <ng-container
                                  *ngTemplateOutlet="addAxisButton"
                                ></ng-container>
                              </div>
                            }
                            <ng-template #addAxisButton>
                              <p-button
                                [rounded]="true"
                                [outlined]="true"
                                icon="pi pi-plus"
                                (onClick)="addAxis()"
                              />
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                } @else {
                  <div class="flex justify-content-end align-items-center m-2">
                    <p-button
                      pRipple
                      label="Nueva configuración"
                      icon="fas fa-plus"
                      [outlined]="true"
                      (onClick)="createNewConfig()"
                    />
                  </div>
                  <div class="p-3 flex flex-column h-full w-full">
                    <satinel-system-grid
                      [data]="configuraciones"
                      [loading]="loading"
                      [columns]="columns"
                      [actions]="actions"
                      selectionMode="single"
                      [(selection)]="selectedConfiguracion"
                      (selectionChange)="calculateEjes(selectedConfiguracion)"
                    />
                  </div>
                }
              </div>
            </ng-template>
            <!-- segunda parte -->
            <ng-template pTemplate>
              <div
                class="surface-100 col h-full flex flex-column overflow-x-hidden gap-2"
              >
                <div
                  class="col overflow-x-hidden border-round"
                  style="
                    min-height: 20rem;
                    height: 100%;
                    background-color: #f1f5f9;
                    padding: 10px;
                  "
                >
                  @if (ejes && ejes.length) {
                    <!-- las imagenes SVG se cargan aqui -->
                    <app-axis-visualizer
                      #axisVisualizer
                      [isTractora]="opcionClaseVehiculo === 'isTractora'"
                      [isVehiculoGenerico]="false"
                      [ejes]="ejes"
                      mode="axis"
                    ></app-axis-visualizer>
                  } @else {
                    <div
                      class="h-full w-full align-items-center justify-content-center"
                    >
                      <span class="text-600 text-lg">
                        Selecciona una configuración para verla
                      </span>
                    </div>
                  }
                </div>
              </div>
            </ng-template>
          </p-splitter>
        </div>
      </div>
    }
  </div>
</ng-template>
